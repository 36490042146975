import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ProtectedAxios from '../api/protectedAxios';
import { BsArrowDownShort, BsArrowLeftShort } from 'react-icons/bs';
import Tags from '../components/Tags';
import { toast } from 'react-hot-toast';
import ManageChatAccess from '../components/ManageChatAccess';
import { UserContext } from '../context/UserProvider';
import placeholderProfile from "../assets/placeholder-profile.jpg";
import MyDropdown from '../components/MyDropdown';
import RenameChat from '../components/RenameChat';
import Messages from '../components/Messages';
import ManageTags from '../components/ManageTags';
import DeleteChat from '../components/DeleteChat';
import ShareChat from '../components/ShareChat';
import { Helmet } from 'react-helmet';
import AdModal from '../components/AdModal';
import MaxAiInteractionsReachedModal from '../components/modals/MaxAiInteractionsReachedModal';
import Template from '../components/Template';
import useSubscriptionDetails from '../hooks/useSubscriptionDetails';
import logo from '../assets/logo.svg';
import { IoSend } from 'react-icons/io5';
import AppToastLayout from '../components/ui/toast/AppToastLayout';

const Chat = ({ updateTokens, isViewingWithSharedAccess = false, isEnterpriseAdmin = false, tokens }) => {
    const [user] = useContext(UserContext);
    const [subscriptionDetail] = useSubscriptionDetails();
    const location = useLocation();
    const navigate = useNavigate();

    const { chat_id } = useParams();
    const chatThreadRef = useRef(null);
    const [showingChatHeader, setShowingChatHeader] = useState(false);

    const [initialLoad, setInitialLoad] = useState(true);
    const [loading, setLoading] = useState(false);

    const [chatDetails, setChatDetails] = useState('');
    const [refreshTags, setRefreshTags] = useState(false); // TODO: check if still needed
    const [messages, setMessages] = useState([]);
    const [pageEnded, setPageEnded] = useState(false)

    const [showMaxInteractionsReachedModel, setShowMaxInteractionsReachedModal] = useState(false)
    const [showAdModal, setShowAdModal] = useState(false)

    
    // Fetch Chat details
    useEffect(() => {
        const isNewChat = (location.state?.chatDetails && location.state?.prompt) ? true : false;
        if (isNewChat) {
            setLoading(false);
            setInitialLoad(false);
            setChatDetails(location.state.chatDetails);
            setTimeout(() => {
                addNewMessage(null, location.state.prompt, true, location.state.addTemplateCreatorAsCollaborator, location.state.chatDetails);
                navigate(`${location.pathname}${location.search}`, { state: {}, replace: true });
            }, 1000)
            return;
        }

        setLoading(true);
        setChatDetails("");
        ProtectedAxios.post('/users/getChatDetails', { chat_id, user_id: user.user_id, isViewingWithSharedAccess, isEnterpriseAdmin })
            .then(res => {
                setChatDetails(res.data);
                setLoading(false);
                setInitialLoad(false);

                // If no chat_description means chat name and description not
                // generated, so generate chat name and description
                if (!res.data.chat_description) {
                    ProtectedAxios.post("/users/createChatNameAndDescription", { chat_id })
                        .then(res => {
                            if (res.data.chat_name && res.data.chat_description) {
                                setChatDetails(prev => ({ ...prev, chat_name: res.data.chat_name, chat_description: res.data.chat_description }));
                            }
                        })
                }
            })
            .catch(err => {
                setLoading(false);
                setInitialLoad(false);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error);
                } else {
                    console.log(err);
                }
            })
    }, []);

    // Toggle sticky chat header & scroll to bottom button visibility
    useEffect(() => {
        const STICKY_CHAT_HEADER_VISIBILITY_OFFSET = 300;
        const scrollButton = document.getElementById('scroll-down-button');

        const handleScroll = () => {
            const scrollY = window.scrollY;
            const scrollHeight = document.body.scrollHeight;

            setShowingChatHeader(scrollY > STICKY_CHAT_HEADER_VISIBILITY_OFFSET);

            if (scrollButton) {
                scrollButton.style.display = scrollHeight - scrollY < 1200 ? 'none' : 'block';
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    //--------------------------------------------------------------------------
    // Export Chat as PDF
    //--------------------------------------------------------------------------
    const [downloadingPdf, setDownloadingPdf] = useState(false);

    const downloadPdf = () => { // TODO: fix me
        setDownloadingPdf(true);
    }


    //--------------------------------------------------------------------------
    // New Message
    //--------------------------------------------------------------------------
    const [addingMessage, setAddingMessage] = useState(false);
    const [promptText, setPromptText] = useState('');
    const [isUsingSelfAPI, setIsUsingSelfAPI] = useState(true);
    const textboxRef = useRef(null);

    const addNewMessage = (e, prompt, is_first_message = false, addTemplateCreatorAsCollaborator, _chatDetails) => {
        // debugger;
        const startTime = performance.now();

        if (e) {
            e.preventDefault();
        }

        if (!chatDetails.is_new_chat && process.env.REACT_APP_SHOW_AD === "true" && subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_A_ID) {
            setShowAdModal(true);
        }

        let chatData = chatDetails;
        if (_chatDetails) {
            chatData = _chatDetails;
        }

        //reset textarea rows
        const textarea = document.getElementById("prompt-input");
        if (textarea) {
            let screenWidth = window.screen.width;
            if (screenWidth <= 786) {
                textarea.rows = 2;
            }
            else {
                textarea.rows = 1;
            }
        }

        setAddingMessage(true);

        // add a temp message,
        // which will be removed when the response is generated
        let createdAt = new Date().toISOString();
        debugger;
        addTempMessage({
            chat_id,
            created_by: user.user_id,
            profile_picture_src: user.profile_picture_src,
            message_content: prompt,
            username: user.username,
            name: user.name,
            created_at: createdAt,
            class: "sc_animate_fade_in_up"
        });

        const promptBackup = prompt; // to restore prompt in case of error
        setPromptText("");

        ProtectedAxios.post("users/addMessage", { user_id: user.user_id, role_id: user.role_id, chat_id, prompt: prompt, customer_id: user.stripe_customer_id, is_using_self_api: isUsingSelfAPI, model_provider: chatData.model_provider, template_id: chatData?.template_ref?.template_id, is_first_message, addTemplateCreatorAsCollaborator })
            .then(res => {
                const endTime = performance.now();
                let timeTaken = endTime - startTime;
                let waitingTime = 15000 - timeTaken;

                if (subscriptionDetail?.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                    waitingTime = 0;
                }

                setTimeout(() => {
                    // show response generated toast if user navigated out of
                    // the chat page
                    const currentPathname = window.location.pathname;
                    let chatUrl = `/chat/${chat_id}`;
                    if (isViewingWithSharedAccess) {
                        chatUrl = `/shared-chat/${chat_id}`;
                    } else if (isEnterpriseAdmin) {
                        chatUrl = `/enterprise-chat/${chat_id}`;
                    }
                    if (currentPathname !== chatUrl) {
                        toast(
                            <AppToastLayout
                                title={chatDetails.chat_name}
                                message={
                                    <>
                                        Your response is generated. Check it <Link to={chatUrl} className="color-link">here</Link>.
                                    </>
                                }
                            />
                        );
                    }

                    setShowAdModal(false);

                    //add new message in the messages state
                    if (res.data) {
                        setChatDetails(prev => { return { ...prev, total_tokens: res.data.total_tokens } });
                        addMessage(res.data);
                    }

                    //update the tokens
                    try {
                        updateTokens();
                    }
                    catch (err) {
                        console.log(err);
                    }


                    setAddingMessage(false);

                    // If first message, generate chat name and description
                    if (is_first_message) {
                        ProtectedAxios.post("/users/createChatNameAndDescription", { chat_id })
                            .then(res => {
                                if (res.data.chat_name && res.data.chat_description) {
                                    setChatDetails(prev => ({ ...prev, chat_name: res.data.chat_name, chat_description: res.data.chat_description }));
                                }
                            })
                    }
                }, waitingTime);
            })
            .catch(err => {
                console.log(err);

                removeLastTempMessage();
                setPromptText(promptBackup);

                const endTime = performance.now();
                let timeTaken = endTime - startTime;
                let waitingTime = process.env.REACT_APP_AD_DURATION - timeTaken;
                if (subscriptionDetail?.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                    waitingTime = 0;
                }

                setTimeout(() => {
                    setShowAdModal(false);
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error);
                    }
                    else if (err.response.status === 401) {
                        toast.error("You dont have enough tokens to add this message, please topup to continue");
                    }
                    else if (err.response.status === 429) {     //max ai interactions reached
                        setShowMaxInteractionsReachedModal(true);
                    }
                    setAddingMessage(false);
                }, waitingTime);
            })
    }
    const addTempMessage = (msg) => {
        setMessages(prev => {
            const updated = [...prev];
            return [...updated, msg]
        })
    }
    const addMessage = (newMsg) => {
        setMessages(prev => {
            let updated = [...prev]
            updated.pop(); // removes the temp message
            return [...updated, newMsg]
        })
    }
    const removeLastTempMessage = () => {
        setMessages(prev => {
            const updated = [...prev]
            updated.pop()
            return updated
        })
    }


    //--------------------------------------------------------------------------
    // Regenerate Response
    //--------------------------------------------------------------------------
    const [regenerateResponse, setRegeneratingResponse] = useState(false);
    const [showingRegenerateOptions, setShowingRegenerateOptions] = useState(false);

    const regenerateLastResponse = (_isUsingSelfAPI) => {
        if (process.env.REACT_APP_SHOW_AD === "true" && subscriptionDetail.price_id === process.env.REACT_APP_PRICE_A_ID) {
            setShowAdModal(true);
        }

        const startTime = performance.now();

        setRegeneratingResponse(true);
        setAddingMessage(true); // to prevent user from adding new message while regenerating response
        setShowingRegenerateOptions(false);

        ProtectedAxios.post("/users/regenerateResponse", { user_id: user.user_id, role_id: user.role_id, chat_id, customer_id: user.stripe_customer_id, is_using_self_api: _isUsingSelfAPI, model_provider: chatDetails.model_provider, template_id: chatDetails?.template_ref?.template_id })
            .then(res => {
                const endTime = performance.now();
                let timeTaken = endTime - startTime;
                let waitingTime = 15000 - timeTaken;

                if (subscriptionDetail.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                    waitingTime = 0;
                }

                setTimeout(() => {
                    setShowAdModal(false);

                    // TODO: do this in a better way
                    // Removing and adding the last message in the messages
                    // after some time gap to make sure that the last message
                    // component re-renders so that new response can be shown to
                    // the user
                    setMessages(prev => {
                        let updatedMessages = [...prev]
                        updatedMessages = updatedMessages.slice(0, updatedMessages.length - 1)
                        return updatedMessages
                    })
                    setTimeout(() => {
                        setMessages(prev => {
                            let updatedMessages = [...prev]
                            updatedMessages.push(res.data)
                            return updatedMessages
                        })
                        setChatDetails(prev => { return { ...prev, total_tokens: res.data.total_tokens } })
                    }, 200)

                    //update the tokens
                    try {
                        updateTokens();
                    }
                    catch (err) {
                        console.log(err);
                    }

                    //show success messages
                    setRegeneratingResponse(false);
                    setAddingMessage(false);

                    //scroll to the new message
                    setTimeout(() => {
                        var element = document.getElementById(`response-${res.data.response_id}`);
                        var headerOffset = 100;
                        var elementPosition = element.getBoundingClientRect().top;
                        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                        window.scrollTo({
                            top: offsetPosition,
                            behavior: "smooth"
                        });
                    }, 1000);
                }, waitingTime)
            })
            .catch(err => {
                console.log(err);

                const endTime = performance.now();
                let timeTaken = endTime - startTime;
                let waitingTime = process.env.REACT_APP_AD_DURATION - timeTaken;

                if (subscriptionDetail.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                    waitingTime = 0;
                }

                setTimeout(() => {
                    setShowAdModal(false);
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error);
                    }
                    else if (err.response.status === 401) {
                        toast.error("You dont have enough tokens to add this message, please topup to continue");
                    }
                    else if (err.response.status === 429) {     //max ai interactions reached
                        setShowMaxInteractionsReachedModal(true);
                        toast.dismiss("regenerate-response");
                    }
                    setRegeneratingResponse(false);
                    setAddingMessage(false);
                }, waitingTime)
            })
    }


    //--------------------------------------------------------------------------
    // Chat Textbox
    //--------------------------------------------------------------------------

    /**
     * Stick textbox to bottom when user scrolls up
     * Make non sticky again when user comes down the page
     */
    const footerRef = useRef(null);
    const footer = document.getElementById("footer");
    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.intersectionRatio >= 0.9) {
                    setIsFooterVisible(entry.isIntersecting);
                } else {
                    setIsFooterVisible(false);
                }
            }, {
                root: null,
                threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
            }
            ),
        [],
    );
    useEffect(() => {
        observer.observe(footer);
        return () => {
            observer.disconnect();
        };
    }, [footer, observer]);


    /**
     * Handle textbox input
     */
    const handleTextareaChange = (e, stateSetterFunction) => {
        const textarea = document.getElementById("prompt-input");
        let screenWidth = window.screen.width
        if (textarea) {
            const lineCount = textarea.value.split('\n').length;
            let size = parseInt(getComputedStyle(document.body).getPropertyValue('--size-xs').slice(0, -2))

            // Calculate the number of rows needed based on line count and content height
            const rowsNeeded = Math.max(lineCount, textarea.scrollHeight / size / 2);

            if (textarea.value.length === 0) {
                if (screenWidth <= 786) {
                    textarea.rows = 2;
                }
                else {
                    textarea.rows = 1;
                }
            } else {
                textarea.rows = rowsNeeded;
            }

            if (stateSetterFunction) {
                stateSetterFunction(textarea.value)
            }
        }
    }
    useEffect(() => {
        handleTextareaChange(); // to give proper height to the textarea
    }, [])


    return (
        <>
            {downloadingPdf
                && <div className='overlay-loading'>
                    <div className="spinner-border spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                    <p>Creating PDF</p>
                </div>

            }
            <div className='chat-main-container' ref={chatThreadRef}>
                <div className='container'>
                    <div id='pdf-header' style={{ display: 'none', justifyContent: 'space-between', alignItems: 'center', margin: '-2rem 0 4rem 0' }}>
                        <a target='_blank' style={{ color: '#222', textDecoration: 'none' }} href='https://www.sagecollab.com/' rel="noreferrer">
                            <div className="home-branding">
                                <img
                                    alt="logo"
                                    src={`${process.env.REACT_APP_BASE_URL}uploads/logo.png`}
                                    className="home-logo"
                                />
                                <span className="home-company">SageCollab</span>
                            </div>
                        </a>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }} id='chat-owner-info'>
                            <div style={{ width: '40px', height: '40px', borderRadius: '100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: `url(${chatDetails.profile_picture_src ? `${chatDetails.profile_picture_src}` : placeholderProfile})` }} />
                            {chatDetails?.username?.substring(0, 20)}{chatDetails?.username?.length > 20 && '...'}
                        </div>
                    </div>
                </div>

                {loading
                    ?
                    <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                        <div className="spinner-border spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>

                    :
                    <>
                        {initialLoad
                            ? ""
                            : chatDetails === ''
                                ?
                                <div className='d-flex justify-content-center align-items-center'>
                                    <p className='text-danger text-center'>chat not found</p>
                                </div>

                                :
                                <div className='pb-5'>
                                    <Helmet>
                                        <title>{chatDetails.chat_name} - SageCollab</title>
                                    </Helmet>
                                    <div className={`${isEnterpriseAdmin ? "mb-5 pb-5" : ""}`}>
                                        {showingChatHeader
                                            &&
                                            <div className='chat-header-fixed' id='prompt-header'>
                                                <div className='question container'>
                                                    <div className='d-flex align-items-center gap-2 overflow-hidden'>
                                                        <div>
                                                            <button type='button' className='button-icon' title='back' onClick={() => navigate(-1)}>
                                                                <BsArrowLeftShort className='font-xl' />
                                                            </button>
                                                        </div>
                                                        <span className="text-truncate pe-3" title={chatDetails.chat_name ? chatDetails.chat_name : chatDetails.message_content.replace(/(\r\n|\r|\n)/g, " ")}>
                                                            {chatDetails.chat_name}
                                                        </span>
                                                    </div>

                                                    <div className='d-flex align-items-center gap-3'>
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <ShareChat showAsLink={false} chat_id={chatDetails.chat_id} chatDetails={chatDetails} setChatDetails={setChatDetails} />
                                                        }
                                                        <MyDropdown m0={true} >
                                                            <div className={`dropdown-item`} onClick={() => downloadPdf()}>
                                                                Export to PDF
                                                            </div>
                                                            {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                                &&
                                                                <div className='dropdown-item'>
                                                                    <ShareChat showAsLink={true} chat_id={chatDetails.chat_id} chatDetails={chatDetails} setChatDetails={setChatDetails} />
                                                                </div>
                                                            }
                                                            {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                                &&
                                                                <div className='dropdown-item'>
                                                                    <ManageChatAccess showAsLink={true} chat_id={chatDetails.chat_id} prompt={chatDetails.message_content} />
                                                                </div>
                                                            }
                                                            {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                                &&
                                                                <div className='dropdown-item'>
                                                                    <RenameChat chat_id={chat_id} prompt={chatDetails.message_content} viewingInChat={true} setChatDetails={setChatDetails} chatDetails={chatDetails} />
                                                                </div>
                                                            }
                                                            {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                                &&
                                                                <div className='dropdown-item'>
                                                                    <ManageTags setRefreshTags={setRefreshTags} viewAsLisk={true} chat_id={chat_id} isViewingWithSharedAccess={isViewingWithSharedAccess} isEnterpriseAdmin={isEnterpriseAdmin} />
                                                                </div>
                                                            }
                                                            {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                                &&
                                                                <div className='dropdown-item'>
                                                                    <DeleteChat chat_id={chat_id} prompt={chatDetails.message_content} chat_name={chatDetails.chat_name} viewingInChat={true} />
                                                                </div>
                                                            }
                                                        </MyDropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className='chat-header container'>
                                            <div className='chat-name'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div>
                                                        <button type='button' className='button-icon' title='back' onClick={() => navigate(-1)}>
                                                            <BsArrowLeftShort className='font-xl' />
                                                        </button>
                                                    </div>
                                                    <span title={chatDetails.chat_name ? chatDetails.chat_name : chatDetails.message_content.replace(/(\r\n|\r|\n)/g, " ")}>
                                                        {chatDetails.chat_name
                                                            ? <>{chatDetails.chat_name}</>
                                                            : <>{chatDetails.message_content.replace(/(\r\n|\r|\n)/g, " ").substring(0, 50)}{chatDetails.message_content.length > 50 && '...'}</>
                                                        }
                                                    </span>
                                                </div>

                                                <div className='d-flex align-items-center gap-3'>
                                                    {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                        &&
                                                        <ShareChat showAsLink={false} chat_id={chatDetails.chat_id} chatDetails={chatDetails} setChatDetails={setChatDetails} />
                                                    }
                                                    <MyDropdown m0={true} >
                                                        <div className={`dropdown-item`} onClick={() => downloadPdf()}>
                                                            Export to PDF
                                                        </div>
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <div className='dropdown-item'>
                                                                <ShareChat showAsLink={true} chat_id={chatDetails.chat_id} chatDetails={chatDetails} setChatDetails={setChatDetails} />
                                                            </div>
                                                        }
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <div className='dropdown-item'>
                                                                <ManageChatAccess showAsLink={true} chat_id={chatDetails.chat_id} prompt={chatDetails.message_content} />
                                                            </div>
                                                        }
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <div className='dropdown-item'>
                                                                <RenameChat chat_id={chat_id} prompt={chatDetails.message_content} viewingInChat={true} setChatDetails={setChatDetails} chatDetails={chatDetails} />
                                                            </div>
                                                        }
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <div className='dropdown-item'>
                                                                <ManageTags setRefreshTags={setRefreshTags} viewAsLisk={true} chat_id={chat_id} isViewingWithSharedAccess={isViewingWithSharedAccess} isEnterpriseAdmin={isEnterpriseAdmin} />
                                                            </div>
                                                        }
                                                        {!isViewingWithSharedAccess && !isEnterpriseAdmin
                                                            &&
                                                            <div className='dropdown-item'>
                                                                <DeleteChat chat_id={chat_id} prompt={chatDetails.message_content} chat_name={chatDetails.chat_name} viewingInChat={true} />
                                                            </div>
                                                        }
                                                    </MyDropdown>
                                                </div>
                                            </div>

                                            <div className='d-flex flex-column mt-4 gap-2'>
                                                <div className='tags p-0'>
                                                    <Tags refreshTags={refreshTags} setRefreshTags={setRefreshTags} downloadingPdf={downloadingPdf} chat_id={chat_id} isViewingWithSharedAccess={isViewingWithSharedAccess} isEnterpriseAdmin={isEnterpriseAdmin} />
                                                </div>
                                            </div>

                                        </div>

                                        <Messages
                                            chat_id={chat_id}
                                            chatDetails={chatDetails}
                                            setChatDetails={setChatDetails}
                                            messages={messages}
                                            setMessages={setMessages}
                                            setAddingMessage={setAddingMessage}
                                            isEnterpriseAdmin={isEnterpriseAdmin}
                                            pageEnded={pageEnded}
                                            setPageEnded={setPageEnded}
                                            tokens={tokens}
                                            updateTokens={updateTokens}
                                            setShowAdModal={setShowAdModal}
                                            setShowMaxInteractionsReachedModal={setShowMaxInteractionsReachedModal}
                                        />

                                        {!isEnterpriseAdmin
                                            &&
                                            <div className={`chat-sticky-footer ${!isFooterVisible ? "is-sticky" : ""}`} ref={footerRef}>
                                                <div className='container'>
                                                    {chatDetails.total_tokens < chatDetails.max_token_limit
                                                        ?
                                                        <>
                                                            <div className='d-flex mb-1'>
                                                                {chatDetails.model_provider === "OPENAI"
                                                                    ?
                                                                    <div className='chat-select-item' title={`Using OpenAI model ${chatDetails.model}`}>
                                                                        <div className='d-flex justify-content-center align-items-center gap-2'>
                                                                            <img src="https://www.svgrepo.com/show/306500/openai.svg" style={{ width: '20px' }} alt="OpenAI" />
                                                                            <p className='m-0 font-xs'>OpenAI</p>
                                                                        </div>
                                                                    </div>

                                                                    : chatDetails.model_provider === "GEMINI"
                                                                        ?
                                                                        <div className='chat-select-item' title={`Using Gemini model ${chatDetails.model}`}>
                                                                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                                                                <img alt="Gemini" src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" style={{ width: '20px' }} />
                                                                                <p className='m-0 font-xs'>Gemini</p>
                                                                            </div>
                                                                        </div>

                                                                        : chatDetails.model_provider === "BEDROCK"
                                                                            ?
                                                                            <div className='chat-select-item' title={`Using Gemini model ${chatDetails.model}`}>
                                                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                                                    <img alt="Bedrock" src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" className='rounded-circle' style={{ width: '25px' }} />
                                                                                    <p className='m-0 font-xs'>Bedrock</p>
                                                                                </div>
                                                                            </div>

                                                                            :
                                                                            <div className='chat-select-item'>
                                                                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                                                                    <img alt="SageCollab" src={logo} style={{ width: '20px' }} />
                                                                                    <p className='m-0 font-xs'>SageCollab</p>
                                                                                </div>
                                                                            </div>
                                                                }
                                                            </div>
                                                            <form ref={textboxRef} id='add-message-form' className='add-message-container' onSubmit={e => addNewMessage(e, promptText)}>
                                                                <div className='textarea-container template-grp'>
                                                                    <button id='copy-promptReply-response-button' disabled={promptText.length === 0 || addingMessage} className='send-message-button' title='send'>
                                                                        {addingMessage
                                                                            ? <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            : <IoSend className='edit-icon copy-icon' />
                                                                        }
                                                                    </button>
                                                                    <textarea
                                                                        placeholder="type here to continue or type '/' to select a template"
                                                                        rows={1}
                                                                        required
                                                                        id="prompt-input"
                                                                        value={promptText}
                                                                        onChange={e => handleTextareaChange(e, setPromptText)}
                                                                    >
                                                                    </textarea>
                                                                    <Template placement='top' textInput={promptText} handleTextareaChange={handleTextareaChange} setPrompt={setPromptText} showNormalOnly={true} />
                                                                </div>
                                                            </form>
                                                        </>
                                                        :
                                                        <div className='d-flex mt-5 flex-column align-items-center'>
                                                            <div className='chat-info mb-5'>
                                                                <span>Max tokens limit reached</span>
                                                            </div>
                                                            <Link to="/start-chat" className='button'>Start New Chat</Link>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <button type='button' id='scroll-down-button' className='edit-btn scroll-down-button' title='scroll down' onClick={() => window.scrollTo(0, document.body.scrollHeight)}>
                                        <BsArrowDownShort className='edit-icon copy-icon' />
                                    </button>
                                </div>
                        }
                    </>
                }
            </div>


            <MaxAiInteractionsReachedModal
                show={showMaxInteractionsReachedModel}
                onHide={() => setShowMaxInteractionsReachedModal(false)}
            />
            <AdModal
                show={showAdModal}
                onHide={() => setShowAdModal(false)}
            />
        </>
    )
}

export default Chat